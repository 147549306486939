import React, { useState, useEffect, useMemo } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton, Select, Input, message } from 'antd';
import iceLog from '@/utils/iceLog.js'
import axios from 'axios';
import PopupAir from '@/components/popupAir'
// import { getList } from '../api/index'

const hostDir = process.env.REACT_APP_API_URL
const wsDir = process.env.REACT_APP_WS_URL

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  return (
      <div>
          <button onClick={() => onPageChange(1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>First</button>
          <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;&nbsp;</button>
          <span className="text-white border border-white p-1"> Page {currentPage} of {totalPages} </span>
          <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>Last</button>
      </div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const toTokenInfo = (address, creator, symbol, hotlist, pair) => {
    // if(hotlist < 3) {
      navigate(`/${address}`);
    // } else {
    //   window.open('https://sepolia.etherscan.io/address/0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008#writeContract');
    // }
  };

  const [data, setData] = useState([]);
  const [txToken, setTxToken] = useState({"address":"0x"});
  const [hotToken, setHotToken] = useState({"address":"0x"});
  const [ethPrice, setEthPrice] = useState(0);
  const picDir = hostDir + "uploads/";
  const [txTime, setTxTime] = useState(0);
  const [count, setCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(29);
  const [totalPages, setTotalPages] = useState(1);
  const [modalStatus, setModalStatus] = useState(false);

  const { Option } = Select;
  const [sortOption, setSortOption] = useState("time");
  const { Search } = Input;
  const [searchData, setSearchData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  // 处理搜索
  const handleSearch = (value) => {
    if(value === "") {
      setSearchData([]);
    } else {
      fetch(`${hostDir}searchInfo?condition=${encodeURIComponent(value)}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setSearchData(data);
        } else {
          setSearchData([]);
          messageApi.error("No relevant token's information found.");
        } })
      .catch(error => console.error('Error fetching data:', error));
    }
  };

  // useEffect(() => {
  //   // Call the back-end API endpoint to get the data
  //   fetch(hostDir + 'seatest')
  //   //fetch('http://localhost:3000/seatest')
  //     .then(response => response.json())
  //     .then(data => setData(data))
  //     .catch(error => console.error('Error fetching data:', error));
  // }, [count]);

  useEffect(() => {
    fetch(`${hostDir}totalPages?pageSize=${encodeURIComponent(pageSize)}`)
      .then(response => response.json())
      .then(data => setTotalPages(data.totalPages))
      .catch(error => console.error('Error fetching data:', error));
    let pageSizeIn = pageSize;
    if(page === 1 && sortOption === "time") pageSizeIn += 1;
    fetch(`${hostDir}page?page=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(pageSizeIn)}&option=${encodeURIComponent(sortOption)}`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [page, pageSize, sortOption]);

  useEffect(() => {
    fetch(hostDir + 'latestTx')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setTxToken(data[0]);
        } else {
          console.log('No data retrieved about latestTx.');
        } })
      .catch(error => console.error('Error fetching data:', error));
  }, [txTime]);

  useEffect(() => {
    fetch(hostDir + 'hotList')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setHotToken(data[0]);
        } else {
          console.log('No data retrieved.');
        } })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    fetch(`${hostDir}ethprice`)
      .then(response => response.json())
      .then(data => {
        console.log(data[0]);
        if (data && data.length > 0) {
          setEthPrice(data[0].ethusd);
          console.log(data[0].ethusd);
        } else {
          console.log('No data retrieved.');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  let ws = null;
  let heartbeatInterval = null;
  useEffect(() => {
    if(!ws) ws = new WebSocket(wsDir + '?topic=home');

    ws.onopen = () => {
      console.log('WebSocket connected');

      // Send a heartbeat message every 30 seconds.
      heartbeatInterval = setInterval(function() { ws.send(JSON.stringify({ type: 'ping' })); }, 30000);
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if(message.time && message.time > 0) setTxTime(Number(message.time));
      if(message.count && message.count > 0) setCount(Number(message.count));
      console.log('event.data', event.data);
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
      clearInterval(heartbeatInterval);
    };

    ws.onerror = (event) => {
      if (event instanceof Event) {
          console.error('WebSocket error:', event);
      } else {
          const { message, code } = event;
          console.error(`WebSocket error: ${message} (Code: ${code})`);
      }
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
      clearInterval(heartbeatInterval);
    };
  }, []);

  useEffect(() => {
    if(txTime > 0) {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
        }, 1000);
    }
  }, [txTime]);

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  return (
    <>
		{contextHolder}
    <div className="min-h-[100vh] select-none overscroll-y-auto">
      <PopupAir modalStatus={modalStatus} handleClose={() => { setModalStatus(false) }} />
      <div className="flex flex-col items-center w-full pt-20">
        <br /><br />
        <div className="inline-flex items-center justify-center rounded-md font-medium h-10 px-4 py-2 mb-4 text-2xl text-slate-50 hover:font-bold cursor-pointer" onClick={() => {navigate('/create')}}>[start a new coin]
          on&nbsp;<img className='w-[25px] cursor-pointer' src={require('@/assets/img/chain-light.png')} />base chain
        </div>
        <br />
        <div className="text-white max-w-[800px] grid gap-2 border border-transparent hover:border-white" onClick={() => {navigate('/point')}}> 
          <div className="p-2 flex gap-2 w-full">
            <div className="min-w-20">
              <img className="mr-4 w-20 h-20 object-contain" src={picDir + 'airdrop.webp'} alt={hotToken.pic} />
            </div>
            <div className="gap-1 grid h-fit">
              <p className="text-2xl text-red">
                Point System
              </p>
              <p className="text-xs text-green-300">
                Airdrop Through Platform System Points.<br />
                The airdrop feature will be launching soon.
              </p>
            </div>
          </div>
        </div>
        { hotToken.address.length > 10 && <img src={picDir + "top.png"} alt="market leading" className="h-16" />}
        <div className="text-white max-w-[800px] grid gap-2 border border-transparent hover:border-white"> 
            { hotToken.address.length > 10 && <div className="p-2 flex gap-2 w-full" onClick={() => {toTokenInfo(hotToken.address, hotToken.creator, hotToken.symbol, hotToken.hotlist, hotToken.pair)}}>
              <div className="min-w-20">
                <img className="mr-4 w-20 h-20 object-contain" src={picDir + hotToken.pic} alt={hotToken.pic} />
              </div>
              <div className="gap-1 grid h-fit">
                {hotToken.hotlist > 2 && <p className="text-xs text-blue-300">{'Bonded uniswap >>>'}</p>}
                { hotToken.creator && hotToken.creator.length > 7 && <div className="text-xs text-blue-200 flex items-center gap-2">
                  <div>Created by 👩‍🚀 {hotToken.creator.substring(0, 6)}</div>
                </div> }
                <p className="text-xs text-green-300">Market Cap: ${Math.floor(Number(hotToken.price) * 10**10 * Number(ethPrice)).toLocaleString()} {hotToken.hotlist > 0 ? ' 【badge:🔥】' : ''}</p>
                <p className="text-xs">trade count: {hotToken.tradecount}</p>
                <p className="text-sm w-full textMore">
                  <strong>{hotToken.name}({hotToken.symbol})</strong>
                </p>
              </div>
            </div> }
        </div>
      </div>
      <br />
      <div  style={{ display: 'flex', justifyContent: 'center' }}>
        <Search placeholder="Enter token's name or symbol..." onSearch={handleSearch} enterButton="Search" style={{ width: 500 }}/>
      </div>
      {data.length > 0 && searchData.length === 0 && <div>
        <div className='pl-6 text-white text-xl'>Sort By:&nbsp;&nbsp;&nbsp;
          <Select value={sortOption} onChange={handleSortChange} style={{ width: 150 }}>
            <Option value="time">Trade Time</Option>
            <Option value="cap">Market Cap</Option>
            <Option value="count">Trade Count</Option>
          </Select>
        </div>
        <div className='grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 px-4 sm:px-4 text-gray-400 gap-4 mt-[30px] pb-[50px]'>
        { txToken.address.length > 10 && <div className={animate ? 'shaking' : ''}>
            <div className="p-2 flex border border-transparent hover:border-white gap-2 w-full cursor-pointer" onClick={() => {toTokenInfo(txToken.address, txToken.creator, txToken.symbol, txToken.hotlist, txToken.pair)}}>
              <div className="w-32 h-32">
                <img className="mr-4 w-32 h-32 object-contain" src={picDir + txToken.pic} alt={txToken.pic} />
              </div>
              <div className="gap-1 grid h-fit flex-1">
                { txToken.creator && txToken.creator.length > 7 && <div className="text-xs text-blue-200 flex items-center gap-2">
                  <div>Created by 👩‍🚀 {txToken.creator.substring(0, 6)}</div>
                </div> }
                <p className="text-xs text-green-300">Market Cap: ${Math.floor(Number(txToken.price) * 10**10 * Number(ethPrice)).toLocaleString()} {txToken.hotlist > 0 ? ' 【badge:🔥】' : ''}</p>
                <p className="text-xs">trade count: {txToken.tradecount}</p>
                <p className="text-sm w-full textMore">
                  <strong>{txToken.name}({txToken.symbol}): </strong>{txToken.info}
                </p>
              </div>
            </div> 
          </div> }
        { data.length > 0 ? data.map((item, index) => {
            if (txToken && item.address === txToken.address) {
              return null;
            }
        
            return(<div key={index} className='p-2 flex border border-transparent hover:border-white gap-2 w-full cursor-pointer' onClick={() => {toTokenInfo(item.address, item.creator, item.symbol, item.hotlist, item.pair)}}>
              <div className="w-32 h-32">
                <img className="mr-4 w-32 h-32 object-contain" src={picDir + item.pic} alt={item.pic} />
              </div>
              <div className="gap-1 grid h-fit flex-1">
                {item.hotlist > 2 && <p className="text-xs text-blue-300">{'Bonded uniswap >>>'}</p>}
                {item.creator && item.creator.length > 7 && <div className="text-xs text-blue-200 flex items-center gap-2">
                  <div>Created by 👩‍🚀 {item.creator.substring(0, 6)}</div>
                </div>}
                <p className="text-xs text-green-300">Market Cap: ${Math.floor(Number(item.price) * 10**10 * Number(ethPrice)).toLocaleString()} {item.hotlist > 0 ? ' 【badge:🔥】' : ''}</p>
                <p className="text-xs">trade count: {item.tradecount}</p>
                <p className="text-sm w-full textMore">
                  <strong>{item.name}({item.symbol}): </strong>{item.info}
                </p>
              </div>
            </div>
          )}) : Array(12).fill(null).map((item, index) => (
            <div key={index} className='p-2 flex border border-transparent hover:border-white gap-2 w-full cursor-pointer'>
              <Skeleton.Image active />
              <Skeleton active />
            </div>
          ))
        }
      </div>
      <div className="max-w-[800px] mx-auto grid gap-2 border border-transparent">
        <PaginationControls currentPage={page} totalPages={totalPages} onPageChange={setPage} />
      </div></div>}
      { searchData.length > 0 && <div>
        <div className='grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 px-4 sm:px-4 text-gray-400 gap-4 mt-[30px] pb-[50px]'>
          {searchData.map((item, index) => {
            return(<div key={index} className='p-2 flex border border-transparent hover:border-white gap-2 w-full cursor-pointer' onClick={() => {toTokenInfo(item.address, item.creator, item.symbol, item.hotlist, item.pair)}}>
              <div className="w-32 h-32">
                <img className="mr-4 w-32 h-32 object-contain" src={picDir + item.pic} alt={item.pic} />
              </div>
              <div className="gap-1 grid h-fit flex-1">
                {item.hotlist > 2 && <p className="text-xs text-blue-300">{'Bonded uniswap >>>'}</p>}
                {item.creator && item.creator.length > 7 && <div className="text-xs text-blue-200 flex items-center gap-2">
                  <div>Created by 👩‍🚀 {item.creator.substring(0, 6)}</div>
                </div>}
                <p className="text-xs text-green-300">Market Cap: ${Math.floor(Number(item.price) * 10**10 * Number(ethPrice)).toLocaleString()} {item.hotlist > 0 ? ' 【badge:🔥】' : ''}</p>
                <p className="text-xs">trade count: {item.tradecount}</p>
                <p className="text-sm w-full textMore">
                  <strong>{item.name}({item.symbol}): </strong>{item.info}
                </p>
              </div>
            </div>
          )})}
        </div>
      </div>}
    </div>
    </>
  );
};

export default HomePage;